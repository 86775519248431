import React from "react"
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby"

import "./CarouselOfAcademies.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselOfAcademies = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "carouselOfAcademyNames"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                carouselHeading
                academyNames {
                  name
                  link
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const dataShuffled = data.academyNames.sort(() => Math.random() - 0.5)

  var carouselSettings = {
    dots: false,
    arrows: false,
    draggable: false,
    swipe: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const Bubble = ({ text, link }) => {
    return (
      <div className="CarouselOfAcademiesthoughtContainer">
        <a href={link} target="_blank" rel="noreferrer">
          <div className="CarouselOfAcademiesthought">
            <div className="CarouselOfAcademiesthoughtInner">
              <p>{text}</p>
            </div>
          </div>
        </a>
      </div>
    )
  }

  return (
    <div className="CarouselOfAcademies">
      <h3>{data.carouselHeading}</h3>
      <div className="CarouselOfAcademiesContainer">
        <Slider {...carouselSettings}>
          {dataShuffled.map(S => (
            <div className="CarouselItemContainer">
              <Bubble
                text={S.name}
                link={S.link}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default CarouselOfAcademies
