import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeroBox from "../components/HeroBox"
import LayoutInner from "../components/LayoutInner"
import ClientsCountText from "../components/ClientsCountText"
import Description from "../components/Description"
import ClientProfiles from "../components/ClientProfiles"
import FullWidthBar from "../components/FullWidthBar"
import CarouselOfSchools from "../components/CarouselOfSchools"
import CarouselOfAcademies from "../components/CarouselOfAcademies"
import ContactBar from "../components/ContactBar"

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "clients"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              headerTitle
              headerSubtitle
              paragraph1Text
            }
          }
        }
      }
    }
  }
`

const ClientsPage = ( props ) => {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <>
      <Layout>
        <Seo title="Our Clients" />
        <HeroBox heading={data.headerTitle} text={data.headerSubtitle} />
        <LayoutInner>
          <ClientsCountText />
          <Description text={data.paragraph1Text} />
          <br></br><FullWidthBar>
            <ClientProfiles />
          </FullWidthBar>
          <CarouselOfSchools />
          <CarouselOfAcademies />
        </LayoutInner>
        <ContactBar />
      </Layout>
    </>
  )
}

export default ClientsPage
