import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./ClientProfiles.css"

const ClientProfiles = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "clients"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                testimonials {
                  heading
                  text
                  image {
                    childImageSharp {
                      fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <div className="ClientProfilesContainer">
      {data.testimonials.map((T, index) => (
        <>
          {index % 2 == 0 ?
            <div className="ClientProfileContainer">
              <div className="ClientProfileTextContainer">
                <h4>{T.heading}</h4>
                <p>{T.text}</p>
              </div>
              <div className="ClientProfileImageContainer">
                <Img fluid={T.image.childImageSharp.fluid} />
              </div>
            </div> :
            <div className="ClientProfileContainer CPCright">
              <div className="ClientProfileImageContainer">
                <Img fluid={T.image.childImageSharp.fluid} />
              </div>
              <div className="ClientProfileTextContainer CPTright">
                <h4>{T.heading}</h4>
                <p>{T.text}</p>
              </div>
            </div>
          }
        </>
      ))}
    </div>
  )
}

export default ClientProfiles
